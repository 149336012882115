<template>
<div>
    <hb-modal
        size="large"
        :title="title"
        v-model="dialog"
        @close="closeWindow"
        height="full"
        :footer-cancel-option="editMode ? false : true"
        :footer-off="editMode"
        :force-scroll-to-top.sync="forceScrollToTopSyncer"
        :recalculate-content-position.sync="recalculateContentPositionSyncer"
        @content-position="setContentPosition"
        show-help-link
    >
        <template v-slot:sticky>
            <hb-tabs
                v-show="editMode"
                v-model="activeTab"
                background-color="white"
                @change="forceScrollToTop"
            >
                <v-tab
                    v-for="item in tabOptions"
                    :key="item.key"
                    :ripple="false"
                >
                    {{ item.label }}
                </v-tab>
            </hb-tabs>
            <v-divider v-if="editMode"></v-divider>
            <hb-stepper v-model="stepper" v-if="!editMode">
                <hb-stepper-header>
                    <hb-stepper-step
                        @click="goToStep(1)"
                        :complete="steps[0].status == 'step-complete'"
                        :rules="[() => steps[0].status !== 'step-error']"
                        key="1-step"
                        step="1"
                    >
                        General
                    </hb-stepper-step>

                    <v-divider></v-divider>

                    <hb-stepper-step 
                        @click="goToStep(2)"
                        :complete="steps[1].status == 'step-complete'"
                        :rules="[() => steps[1].status !== 'step-error']"
                        key="2-step" 
                        step="2"
                    >
                        Move-In / Transfer
                    </hb-stepper-step>
                    
                    <v-divider></v-divider>

                    <hb-stepper-step 
                        @click="goToStep(3)"
                        :complete="steps[2].status == 'step-complete'"
                        :rules="[() => steps[2].status !== 'step-error']"
                        key="3-step"
                        step="3"
                    >
                        Move-Out
                    </hb-stepper-step>

                     <v-divider></v-divider>

                    <hb-stepper-step 
                        @click="goToStep(4)"
                        :complete="steps[3].status == 'step-complete'"
                        :rules="[() => steps[3].status !== 'step-error']"
                        key="4-step"
                        step="4"
                    >
                        Payment Cycles
                    </hb-stepper-step>

                </hb-stepper-header>
            </hb-stepper>
        </template>
        <template v-slot:subheader>
            {{ subheaderDescription }}
        </template>
        <template v-slot:content>
            <hb-modal v-model="showDialog" show-help-link size="medium" title="Discard Setup" confirmation>
                <template v-slot:content>
                    <div class="px-6 py-4">
                        Are you sure you want to discard this setup?
                    </div>
                    <div class="px-6 pb-4">
                        If you do, you will lose all the information you’ve entered. Continue the setup process to ensure Hummingbird saves your information.   
                    </div>
                </template> 
                <template v-slot:actions>
                    <hb-btn color="primary"  @click="$emit('close')">Discard</hb-btn>
                </template>
            </hb-modal>
            
            <div v-if="editMode">
                <v-tabs-items v-model="activeTab">
                    <v-tab-item>
                        <GeneralConfigurations :content-position="contentPosition" :template.sync="template" :activelyEditing="activelyEditing" :editMode="editMode" ref="GeneralConfigurations" @saveData="saveNewLease()" @cancelEdit="cancelEdit()"></GeneralConfigurations>
                    </v-tab-item>
                    <v-tab-item>
                        <MoveInTransferConfigurations :content-position="contentPosition" :template.sync="template" :activelyEditing="activelyEditing" :editMode="editMode" :isOPS="isOPS" ref="MoveInTransferConfigurations" @saveData="saveNewLease()" @cancelEdit="cancelEdit()" :active-tab="activeTab"></MoveInTransferConfigurations>
                    </v-tab-item>
                    <v-tab-item>
                        <MoveOut
                            :content-position="contentPosition" 
                            :template.sync="template"
                            :editMode="editMode"
                            :activelyEditing="activelyEditing"
                            @saveData="saveNewLease()"
                            @cancelEdit="cancelEdit()"
                        ></MoveOut>
                    </v-tab-item>
                    <v-tab-item>
                        <BillingConfigurations :content-position="contentPosition" :template.sync="template" :activelyEditing="activelyEditing" :editMode="editMode" :isOPS="isOPS" ref="BillingConfigurations" @saveData="saveNewLease()" @cancelEdit="cancelEdit()" :active-tab="activeTab"></BillingConfigurations>
                    </v-tab-item>
                </v-tabs-items>
            </div>

            <div v-else>
                <GeneralConfigurations v-show="stepper === 1" :content-position="contentPosition" :template.sync="template" :activelyEditing="activelyEditing" ref="GeneralConfigurations"></GeneralConfigurations>
                <MoveInTransferConfigurations v-show="stepper === 2" :content-position="contentPosition" :template.sync="template" :activelyEditing="activelyEditing" :isOPS="isOPS" ref="MoveInTransferConfigurations"></MoveInTransferConfigurations>
                <move-out
                    v-show="stepper === 3"
                    :content-position="contentPosition"
                    :template.sync="template"
                    :activelyEditing="activelyEditing"
                />
                <BillingConfigurations v-show="stepper === 4" :content-position="contentPosition" :template.sync="template" :activelyEditing="activelyEditing" :isOPS="isOPS" ref="BillingConfigurations"></BillingConfigurations>
            </div>
        </template>
        <template v-slot:actions>
            <hb-btn v-if="!editMode" color="primary"  @click="next" :disabled="isLoading($options.name)" :loading="isLoading($options.name)">{{stepper === 4 ? 'Save' :'Next'}}</hb-btn>
        </template>
    </hb-modal>
</div>
</template>

<script>
import Status from '../../includes/Messages.vue';
import Loader from '../../assets/CircleSpinner.vue';
import api from '../../../assets/api.js';
import MoveOut from './MoveOut.vue';
import GeneralConfigurations from './GeneralConfigurations.vue';
import BillingConfigurations from './BillingConfigurations.vue';
import MoveInTransferConfigurations from './MoveInTransferConfigurations.vue';
import { EventBus } from '../../../EventBus.js';
import HoverableEditField from "../../assets/HoverableEditField.vue";
import { notificationMixin } from "../../../mixins/notificationMixin.js";

export default {
    name : 'CreateNewLeaseDialog',
    components : {
        Loader,
        Status,
        MoveOut,
        HoverableEditField,
        GeneralConfigurations,
        BillingConfigurations,
        MoveInTransferConfigurations
    },
    mixins: [notificationMixin],
    props:[ 'leaseTemeplateId', 'value', 'isOPS' ],
    data() {
        return {
            tabOptions:[
                { label: 'General', key: 1 },
                { label: 'Move-In / Transfer', key: 2 },
                { label: 'Move-Out', key: 3 },
                { label: 'Payment Cycle', key: 4 },
            ],
            activeTab: null,
            stepper: 1,
            // editFlag: false,
            showDialog : false,
            showEdit : false,
            open: '',
            template: {
                name: '',
                security_deposit: false,
                security_deposit_type: null,
                deposit_amount: null,
                invoiceSendDay: 10,
                unit_type: 'storage',
                is_default: false,
                security_deposit_months: "1",
                lease_type: 'Month to Month',
                bill_day: '1st of the Month',
                lease_duration: 1,
                lease_duration_type: 'Months',
                terms: '',
                email_statement: true,
                email_consolidated_statement: false,
                tax_rent: false,
                prorate_rent: true,
                prorate_days: 1,
                prorate_rent_out: true,
                prorate_monthly_charges: false,
                allow_back_days: 1,
                backDaysCheck: true,
                auto_pay: false,
                auto_pay_after_billing_date: 0,
                auto_pay_max_times: 10,
                enable_payment_cycles: false,
                revert_payment_cycle: 0,
                billing_periods: [], 
                checklist: {
                    lease: [],
                    autopay: [],
                    'enroll-coverage': [],
                    'deny-coverage': [],
                    vehicle: [],
                    military: [],
                    other: [],
                    nameOnly: []
                },
                products: {
                    movein: [{}],
                    transfer: [{}],
                    service: [{}]
                }
            },
            clonedTemplate: {},
            gate_code_formats: [
                'Numeric Characters',
                'Alphanumeric Characters'
            ],
            showAddService: false,
            addServiceType: null,
            addChecklistItem: false,
            activelyEditing: '',
            maxActiveStep: 1,
            steps: [{
                number: 1,
                status: 'step-active' 
            }, {
                number: 2,
                status: 'step-disabled'
            }, {
                number: 3,
                status: 'step-disabled'
            },
                {
                number: 4,
                status: 'step-disabled'
            }],
            contentPosition: '',
            forceScrollToTopSyncer: false,
            recalculateContentPositionSyncer: false,
            dynamicHeight: '0',
        }
    },
    created(){
        EventBus.$on('saveData', this.saveNewLease);
        EventBus.$on('activeEdit', this.setActiveEditName);
        EventBus.$on('lease-configuration-revert-to-cached-template', this.revertToCachedTemplate);
        this.reset();
        if(this.leaseTemeplateId) {
            this.fetchData();
        }
    },
    destroyed() {
        EventBus.$off('saveData', this.saveNewLease);
        EventBus.$off('activeEdit', this.setActiveEditName);
        EventBus.$off('lease-configuration-revert-to-cached-template', this.revertToCachedTemplate);
        this.closeMessageNotification();
    },
    computed: {
        dialog: {
            get () {
                return this.value
            },
            set (value) {
                //this.$emit('input', value)
            }
        },
        editMode() {
            if (this.leaseTemeplateId) {
                return true;
            }
            return false;
        },
        subheaderDescription(){
            if(this.editMode){
                if(this.activeTab === 0){
                    return "The name and description for this lease.";
                } else if(this.activeTab === 1){
                    return "Details, rules, and fees involved with a tenant's move-in / transfer process."
                } else if(this.activeTab === 2){
                    return "Details, rules, and fees involved with a tenant’s move-out process."
                } else if(this.activeTab === 3){
                    return "Configure multi-month Payment Cycles."
                }
            } else {
                if(this.stepper === 1){
                    return "Step 1: Provide a name and description for this lease."
                } else if(this.stepper === 2){
                    return "Step 2: Provide the details, rules, and fees involved with a tenant's move-in / transfer process."
                } else if(this.stepper === 3){
                    return "Step 3: Provide the details, rules, and fees involved with a tenant’s move-out process."
                } else if(this.stepper === 4){
                    return "Step 4: Configure multi-month payment cycles."
                }
            }
        }
    },
    methods: {
        async goToStep(stepNumber, previousStepCompleted) {
            const maxStep = 4;

            // Button click on last step
            if(stepNumber > maxStep) {
                this.saveNewLease();
                return;
            }

            // User clicks on next step button (not on stepper)
            if(previousStepCompleted) {
                this.steps[stepNumber - 1].status = 'step-active'; 
                this.steps[stepNumber - 2].status = 'step-complete';
                this.stepper = stepNumber;
                this.maxActiveStep = stepNumber;
            } else {
                let generalFormErrors = await this.$refs.GeneralConfigurations.validateForm();
                let moveInTransferFormErrors = await this.$refs.MoveInTransferConfigurations.validateForm();
                // let billingFormErrors = await this.$refs.BillingConfigurations.validateForm();

                if(stepNumber === 1 || stepNumber <= this.maxActiveStep) {
                    this.stepper = stepNumber;
                    this.steps[stepNumber - 1].status = 'step-active'; 

                    for(let i = stepNumber + 1; i <= this.maxActiveStep; i++) {
                       this.steps[i - 1].status = 'step-editable'; 
                    }

                    for(let j = stepNumber - 1; j > 0; j--) {
                       this.steps[j - 1].status = 'step-complete'; 
                    }

                    // Note: This should be generic, should be taken care of while creating generic component
                    if(stepNumber == 2) {
                        if(!generalFormErrors) {
                            this.steps[0].status = 'step-error';
                        }
                    } else if(stepNumber == 3) {
                        if(!generalFormErrors) {
                            this.steps[0].status = 'step-error';
                        }
                        if(!moveInTransferFormErrors) {
                            this.steps[1].status = 'step-error';
                        }
                    } else if(stepNumber == 4) {
                        if(!generalFormErrors) {
                            this.steps[0].status = 'step-error';
                        }
                        if(!moveInTransferFormErrors) {
                            this.steps[1].status = 'step-error';
                        }
                    }
                } 
            }
        },
        async fetchData(){
            let data; 
            try {
                let res = await api.get(this, api.TEMPLATES + this.leaseTemeplateId);
                data = res.template;
                this.template = this.transformGetData(data);

                if(this.editMode){
                    await this.reorganizePaymentCycleConfiguration();
                }
                this.clonedTemplate = JSON.parse(JSON.stringify(this.template));
            } catch(err) {
                // err
            }
        },
        setActiveEditName(name) {
            this.activelyEditing = name;
        },
        closeWindow(){
            if(this.editMode || this.stepper == 1) {
                this.$emit('close');
            } else {
                this.showDialog = true;
            }
        },
        async reset() {
            this.stepper = 1;
            if (this.leaseTemeplateId){
                // this.editFlag = true;
                this.title = 'Lease Configuration'
            } else {
                this.title = 'Add New Lease Configuration to the Library'
            }
        },
        async next () {
            let generalFormValidator = await this.$refs.GeneralConfigurations.validateForm();
            let moveInTransferFormValidator = true;
            if(this.stepper == 2) {
                moveInTransferFormValidator = await this.$refs.MoveInTransferConfigurations.validateForm();
            }

            if(generalFormValidator && moveInTransferFormValidator) {
                this.$validator.validateAll('form_'+ this.stepper).then(status => {
                    if(!status) return;
                    this.goToStep(this.stepper + 1, true);
                })
            }
        },
        transformPostData() {
            
            const productsValue = Object.values(this.template.products);
            let transformedProducts = productsValue.flat(1);
            const documentsValue = Object.values(this.template.checklist);
            let transformedDocuments = documentsValue.flat(1);

            for(let i = 0; i < transformedProducts.length; i++) {
                if(!transformedProducts[i].product_id) {
                    transformedProducts[i]["product_id"] = transformedProducts[i]["id"];
                    delete transformedProducts[i]["id"];
                }
            }
            
            transformedProducts = transformedProducts.filter(x => x.product_id);
            transformedDocuments = transformedDocuments.filter(x => x.name);
            transformedDocuments = transformedDocuments.filter(x => x.document_id != "");

            const transformedTemplate = {
                ...this.template
            };
            
            transformedTemplate.products = transformedProducts;   
            transformedTemplate.checklist = transformedDocuments;
            transformedTemplate.allow_back_days = transformedTemplate.backDaysCheck ? transformedTemplate.allow_back_days : 0;
         
            return transformedTemplate;
        },
        transformGetData(data) {
            const transformedObject = { ...data };

            transformedObject.backDaysCheck = transformedObject.allow_back_days > 0;
            transformedObject.products = {
                movein: [{}],
                transfer: [{}],
                service: [{}]
            };
            
            let p = {};
            let productValues = data.products;
            for (let i = 0; i < productValues.length; i++) {   
                p[productValues[i].category_type] = p[productValues[i].category_type] || [];
                productValues[i].category_type && p[productValues[i].category_type].push(productValues[i]);

                if(productValues[i].category_type) {
                    transformedObject.products[productValues[i].category_type] = p[productValues[i].category_type];
                }
            }

            transformedObject.checklist = {
                lease: [],
                autopay: [],
                'enroll-coverage': [],
                'deny-coverage': [],
                vehicle: [],
                military: [],
                other: [],
                nameOnly: []
            }

            let d = {};
            const docsValues = data.checklist;
            
            for (let i = 0; i < docsValues.length; i++) {
                d[docsValues[i].document_tag || 'nameOnly'] = d[docsValues[i].document_tag || 'nameOnly'] || [];
                d[docsValues[i].document_tag || 'nameOnly'].push(docsValues[i]);
                transformedObject.checklist[docsValues[i].document_tag || 'nameOnly'] = d[docsValues[i].document_tag || 'nameOnly'];
            }

            return transformedObject;
        },
        displayMessageNotification(message, isError) {
                if(!isError) {
                    this.showMessageNotification({ id: this.$options.name, type: "success", description: message });
                } else {
                    this.showMessageNotification({ id: this.$options.name, type: "error", list: [{ msg: message }] });
                }
            },displayMessageNotification(message, isError) {
                if(!isError) {
                    this.showMessageNotification({ id: this.$options.name, type: "success", description: message });
                } else {
                    this.showMessageNotification({ id: this.$options.name, type: "error", list: [{ msg: message }] });
                }
            },
        async saveNewLease() {
            const leaseConfigData = this.transformPostData();            
              
            let generalFormValidator = await this.$refs.GeneralConfigurations.validateForm();
            let moveInTransferFormValidator = this.$refs.MoveInTransferConfigurations ? await this.$refs.MoveInTransferConfigurations.validateForm() : null;
            let BillingConfigurationsValidator = this.$refs.BillingConfigurations ? await this.$refs.BillingConfigurations.validateForm() : null;
               
            if(generalFormValidator && moveInTransferFormValidator !== false && BillingConfigurationsValidator !== false) {
                let successMessage =  "The '"+this.template.name;
                let currentLocalDate = this.$options.filters.formatDateTimeCustom(
                        new Date(),
                        "MMM DD, YYYY @ h:mma."
                    );
                try {
                    if(!this.editMode) {
                        const response = await api.post(this, api.TEMPLATES, leaseConfigData);
                        
                        successMessage += "' has been created on " + currentLocalDate ;
                        this.$emit('saved');
                        this.$emit('close');
                    } else {
                        const response = await api.put(this, `${api.TEMPLATES}/${this.leaseTemeplateId}`, leaseConfigData);
                        this.$emit('saved');
                        let awaitFetchData = await this.fetchData();
                        successMessage += "' has been updated on " + currentLocalDate ;
                        this.activelyEditing = '';
                        EventBus.$emit('lease-configuration-edit-mode-clear-active');
                        setTimeout(() => {
                            this.recalculateContentPositionSyncer = true;
                        }, 200);
                    }

                    this.displayMessageNotification(successMessage, false);
                } catch (error) {
                    this.$emit('saved');
                    let awaitFetchData = await this.fetchData();
                    this.activelyEditing = '';
                    EventBus.$emit('lease-configuration-edit-mode-clear-active');
                    this.displayMessageNotification(error, true);
                    setTimeout(() => {
                        this.recalculateContentPositionSyncer = true;
                    }, 200);
                }
            }
        },
        cancelEdit(){
            this.template = JSON.parse(JSON.stringify(this.clonedTemplate));
        },
        setContentPosition(e){
            this.contentPosition = e;
            this.dynamicHeight = this.contentPosition.height;
        },
        forceScrollToTop(){
            this.forceScrollToTopSyncer = true;
        },
        revertToCachedTemplate(cachedTemplate){
            this.template = JSON.parse(JSON.stringify(cachedTemplate));
            this.clonedTemplate = JSON.parse(JSON.stringify(cachedTemplate));
        },
        clearAllActive(){
            EventBus.$emit('lease-configuration-edit-mode-clear-active');
            this.template = JSON.parse(JSON.stringify(this.clonedTemplate));
        },
        async reorganizePaymentCycleConfiguration(){
            let quarterly = this.template.payment_cycle_options?.length && this.template.payment_cycle_options.find(p => p.label.toLowerCase() === 'quarterly');
            let annual = this.template.payment_cycle_options?.length && this.template.payment_cycle_options.find(p => p.label.toLowerCase() === 'annual');

            this.template.payment_cycle_options =  this.template.payment_cycle_options || [];

            if(quarterly && (this.template.payment_cycle_options && this.template.payment_cycle_options[0] && this.template.payment_cycle_options[0].label && this.template.payment_cycle_options[0].label.toLowerCase() === 'quarterly') || (this.template.payment_cycle_options && this.template.payment_cycle_options[1] && this.template.payment_cycle_options[1].label && this.template.payment_cycle_options[1].label.toLowerCase() === 'quarterly')){
                if(this.template.payment_cycle_options.find(p => p.label.toLowerCase() === 'quarterly') && this.template.payment_cycle_options.find(p => p.label.toLowerCase() === 'quarterly').enabled === undefined){
                this.$set(this.template.payment_cycle_options, 0, {
                    enabled: true,
                    label: 'Quarterly',
                    promotion_id: quarterly && quarterly.promotion_id || ''
                })
                } else {
                this.$set(this.template.payment_cycle_options, 0, {
                    enabled: this.template.payment_cycle_options.find(p => p.label.toLowerCase() === 'quarterly').enabled,
                    label: 'Quarterly',
                    promotion_id: quarterly && quarterly.promotion_id || ''
                })
                }
            } else {
                this.$set(this.template.payment_cycle_options, 0, {
                enabled: !!quarterly,
                label: 'Quarterly',
                promotion_id: quarterly && quarterly.promotion_id || ''
                })
            }

            if(annual && (this.template.payment_cycle_options && this.template.payment_cycle_options[0] && this.template.payment_cycle_options[0].label && this.template.payment_cycle_options[0].label.toLowerCase() === 'annual') || (this.template.payment_cycle_options && this.template.payment_cycle_options[1] && this.template.payment_cycle_options[1].label && this.template.payment_cycle_options[1].label.toLowerCase() === 'annual')){
                if(this.template.payment_cycle_options.find(p => p.label.toLowerCase() === 'annual') && this.template.payment_cycle_options.find(p => p.label.toLowerCase() === 'annual').enabled === undefined){
                this.$set(this.template.payment_cycle_options, 1, {
                    enabled: true,
                    label: 'Annual',
                    promotion_id: annual && annual.promotion_id || ''
                })
                } else {
                this.$set(this.template.payment_cycle_options, 1, {
                    enabled: this.template.payment_cycle_options.find(p => p.label.toLowerCase() === 'annual').enabled,
                    label: 'Annual',
                    promotion_id: annual && annual.promotion_id || ''
                })
                }
            } else {
                this.$set(this.template.payment_cycle_options, 1, {
                enabled: !!annual,
                label: 'Annual',
                promotion_id: annual && annual.promotion_id || ''
                })
            }
        }
    },
    watch: {
        // Error Notification for frontend

        /* below is causing duplicate error message, turning it off */
        /*
        "errors.items"(val) {
     
            this.showMessageNotification({ id: this.$options.name, type: "error", list: val });
        },
        */
       'stepper'(){
            this.forceScrollToTop();
       },
       'activeTab'(){
           if(this.editMode){
                setTimeout(() => {
                    this.clearAllActive();
                }, 500);
           }
       }
    }
}
</script>

<style lang="scss" scoped>
    .mrl--25px{
        margin-left: -24px;
        margin-right: -24px;
    }
    .key-label{
      background: #F9FAFB;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      color: #101318;
    }
    .top-border{
        border-top: 1px solid #DFE3E8;
    }
    .simple-error{
        border: 1px solid #D91E18;
    }
/*New CSS*/

.field-label {
  background-color: #F9FAFB;
  color: #101318;
}
.phone-input {
  height: 32px;
  color: #101318;
  width: 100%;
}
.phone-input:focus {
    outline: none;
}
span{
    color: #101318;
}
.input-error {
    border-bottom: 1px solid red;
}
.phone-error {
    color: red;
    margin-left: 12px;
}

.custom-field-error {
  border-bottom: 2px solid #fb4c4c !important;
}
.tab-bar {
    color:#101318;
    text-transform: none;
    background: #F9FAFB;
}
.edit-mode-loading-wrapper {
    background: #fff;
    position: relative;
    z-index: 99999;
}
.edit-mode-loading {
    position: absolute;;
    top: 40%;
    left: 45%;
    right: 45%;
    bottom: 40%;
}
</style>
